/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #000;
}

.btn-primary {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

html {
  background-color: #FFF;
}

body {
  background-color: inherit;
}

.jumbotron {
  background-color: inherit;
  border: none;
}

@media screen and (max-width: 390px) {
  .jumbotron img {
    width: 100%;
  }
}
